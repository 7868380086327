@tailwind base;
@tailwind components;

.has-tooltip {
  position: relative;
}

.has-tooltip .tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
  left: -100%;
}

.card {
  border-radius: 7px;
  padding: 7px;
  margin: 20px auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  overflow: hidden;
}

.bg-primary-grey{
  --tw-bg-opacity:1;
  background-color: rgb(87 83 78);
}

@tailwind utilities;

